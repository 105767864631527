import styles from "@/components/styles/badge.module.scss";

export interface ProductCardVariationBadgeProps {
  label: string;
}

export const Badge = ({ label }) => {
  return (
    <span className={styles["badge"]}>
      <b>{label}</b>
    </span>
  );
};
