import { Loader } from "@progress/kendo-react-indicators";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { ACCESS_TOKEN } from "@/utils/constants";
import { ACCESS_TOKEN_TYPE } from "@/platform/apolloClient";

function Auth({ children }) {
  const router = useRouter();
  const { data: session, status } = useSession();
  const isLoading = status === "loading";

  const isUser = !!session?.user;

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isUser) {
      if (router.asPath != "/" && router.asPath != "/logged_out")
        router.push(
          `/auth/login?redirectUrl=${encodeURIComponent(router.asPath)}`
        );
      router.push("/auth/login");
    } else {
      const token: ACCESS_TOKEN_TYPE = {
        accessToken: session.accessToken,
        idToken: session.idToken,
        expiration: session.accessExpires,
      };
      localStorage.setItem(ACCESS_TOKEN, JSON.stringify(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isUser) {
    return children;
  }

  // Session is being fetched, or no user.
  // If user is not logged in, useEffect() will redirect.
  return (
    <div>
      {/* https://thrasio.atlassian.net/browse/RD-983 */}
      {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
      <div style={{ marginTop: "15px", textAlign: "center" }}>
        <span className="k-d-inline-flex k-flex-col k-align-items-center">
          <Loader />
        </span>
      </div>
    </div>
  );
}

export default Auth;
