import { ApolloClient, DocumentNode, gql } from "@apollo/client";
import { stringify } from "csv-stringify/sync";
import { FilterState } from "@/components/filters/FilterStateContext";

export interface GetProductsInput extends Partial<FilterState> {}

const makeRequest = async (
  client: ApolloClient<object>,
  query: DocumentNode,
  input: GetProductsInput
) => {
  const { data } = await client.query({
    query: query,
    variables: { input },
    fetchPolicy: "no-cache",
  });

  return data;
};

export const downloadProductCatalog = async (
  client: ApolloClient<object>,
  filterState: FilterState
) => {
  const today = new Date();
  const query = gql`
    query GetProductCatalog($input: GetProductCatalogInput!) {
      getProductCatalog(input: $input) {
        catalog {
          parentAsin
          asin
          description
          brand
          category
          subcategory
          productType
          url
        }
      }
    }
  `;

  const { data } = await client.query({
    query: query,
    variables: {
      input: {
        marketplace: filterState.marketplace,
      },
    },
  });

  const catalog = data.getProductCatalog.catalog;

  const rows = [
    [
      "Parent ASIN",
      "Child ASIN",
      "Detail Page",
      "Brand",
      "Product Description",
      "Category",
      "Sub Category",
      "Product Type",
    ],
  ];
  for (let n in catalog) {
    let product = catalog[n];
    rows.push([
      product.parentAsin,
      product.asin,
      product.url,
      product.brand,
      product.description,
      product.category,
      product.subcategory,
      product.productType,
    ]);
  }

  const csvContent = stringify(rows);

  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.setAttribute("href", url);

  if (today.getDate() == 26) today.setMonth(today.getMonth() + 1);
  const month = today.toString().split(" ")[1];
  link.setAttribute(
    "download",
    `Thrasio_All_Monetizable_Products_${month}/${today.getFullYear()}_${
      filterState.marketplace
    }.csv`
  );

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getProductsRequest = async (
  client: ApolloClient<object>,
  input: GetProductsInput
) => {
  const query = gql`
    query GetProducts($input: GetProductsInput!) {
      getProducts(input: $input) {
        total
        page
        results {
          id
          asin
          image
          marketplace
          category
          productName
          description
          price
          rating
          ratingCount
          trailing7dUnitsSold
          sessions
          hasVariants
          favorited
        }
        categories
      }
    }
  `;

  return makeRequest(client, query, input);
};

export const getAllProductsDownloadRequest = async (
  client: ApolloClient<object>,
  input: GetProductsInput
) => {
  const query = gql`
    query GetAllProducts($input: GetProductsInput!) {
      getAllProducts(input: $input) {
        total
        page
        results {
          parentAsin
          asin
          brand
          url
          category
          subcategory
          productName
          description
          rating
          ratingCount
          price
        }
      }
    }
  `;

  return makeRequest(client, query, input);
};

export const getAllProductsRequest = async (
  client: ApolloClient<object>,
  input: GetProductsInput
) => {
  const query = gql`
    query GetAllProducts($input: GetProductsInput!) {
      getAllProducts(input: $input) {
        total
        page
        results {
          id
          asin
          image
          marketplace
          category
          productName
          description
          price
          rating
          ratingCount
          trailing7dUnitsSold
          sessions
          hasVariants
          favorited
        }
        categories
      }
    }
  `;

  return makeRequest(client, query, input);
};

export const getDealsRequest = async (
  client: ApolloClient<object>,
  input: GetProductsInput
) => {
  const query = gql`
    query GetDeals($input: GetProductsInput!) {
      getDeals(input: $input) {
        total
        page
        results {
          product {
            id
            asin
            image
            marketplace
            category
            productName
            description
            rating
            ratingCount
            hasVariants
            favorited
          }
          promoCode
          dealType
          dealPrice
          originalPrice
          startDate
          endDate
          discount
        }
        categories
        dealTypes
      }
    }
  `;

  return makeRequest(client, query, input);
};

export const getDealsDownloadRequest = async (
  client: ApolloClient<object>,
  input: GetProductsInput
) => {
  const query = gql`
    query GetDeals($input: GetProductsInput!) {
      getDeals(input: $input) {
        total
        page
        results {
          product {
            parentAsin
            asin
            brand
            category
            subcategory
            productName
            description
            rating
            ratingCount
            productType
          }
          promoCode
          dealType
          dealPrice
          originalPrice
          startDate
          endDate
          discount
        }
      }
    }
  `;

  return makeRequest(client, query, input);
};

export const getPartnersListRequest = async (client: ApolloClient<object>) => {
  const query = gql`
    query getPartnersList {
      getPartnersList {
        total
        results {
          name
          type
          thrasioPartnerId
        }
      }
    }
  `;
  const { data } = await client.query({
    query: query,
  });
  return data;
};

export const validateAdminCustomListRequest = async (
  client: ApolloClient<object>,
  input
) => {
  const query = gql`
    query validateAdminCustomList($input: ValidateAdminCustomList!) {
      validateAdminCustomList(input: $input) {
        errors
        message
        productsCount
        dealsCount
        name
        listExist
      }
    }
  `;
  return makeRequest(client, query, input);
};

export const getCustomLists = async (client: ApolloClient<object>, input) => {
  const query = gql`
    query getCustomLists($input: GetCustomListsInput!) {
      getCustomLists(input: $input) {
        results {
          name
          id
          active
          startDate
          endDate
        }
      }
    }
  `;
  const { data } = await client.query({
    query: query,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data;
};

export const getSelectedListRequest = async (
  client: ApolloClient<object>,
  input
) => {
  const query = gql`
    query getCustomList($input: GetCustomListInput!) {
      getCustomList(input: $input) {
        result {
          name
          id
          active
          marketplace
          startDate
          endDate
          allPublishers
          allInfluencers
          creator {
            id
          }
          products {
            asin
          }
          deals {
            product {
              asin
            }
          }
          partners {
            name
            thrasioPartnerId
            type
          }
        }
      }
    }
  `;
  const { data } = await client.query({
    query: query,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data;
};

export const deleteAdminCustomList = async (
  client: ApolloClient<object>,
  input
) => {
  const query = gql`
    mutation deleteAdminCustomListMutation(
      $input: DeleteAdminCustomListInput!
    ) {
      deleteAdminCustomList(input: $input) {
        errors
        message
      }
    }
  `;
  const { data } = await client.query({
    query: query,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data;
};

export const createAdminCustomList = async (
  client: ApolloClient<object>,
  input
) => {
  const mutation = gql`
    mutation createAdminCustomList($input: AdminCustomListCreate!) {
      createAdminCustomList(input: $input) {
        errors
        message
        results {
          id
          name
          active
          allPublishers
          allInfluencers
          marketplace
          products {
            asin
          }
          partners {
            name
            type
            thrasioPartnerId
          }
        }
      }
    }
  `;
  const result = await client.mutate({
    mutation: mutation,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return result;
};

export const updateAdminCustomList = async (
  client: ApolloClient<object>,
  input
) => {
  const mutation = gql`
    mutation updateAdminCustomList($input: AdminCustomListUpdate!) {
      updateAdminCustomList(input: $input) {
        errors
        message
        results {
          id
          name
          active
          allInfluencers
          allPublishers
          creator {
            emailAddress
            firstName
            lastName
          }
        }
      }
    }
  `;
  const { data } = await client.mutate({
    mutation: mutation,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data;
};
