import { createContext, useContext } from "react";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { v4 as uuidv4 } from "uuid";
import styles from "@/components/styles/notification-context.module.scss";

export interface NotificationState {
  message: string;
  duration: number;
  suffix: any;
}

export type NotificationAction = Partial<NotificationState> & {
  type: NotificationTypes;
  uuid?: string;
};

export interface NotificationContainerState {
  infos: { [key: string]: NotificationState };
  errors: { [key: string]: NotificationState };
  successes: { [key: string]: NotificationState };
}

export enum NotificationTypes {
  SUCCESSES = "successes",
  ERRORS = "errors",
  INFOS = "infos",
}

export const initialNotificationState: NotificationContainerState = {
  infos: {},
  errors: {},
  successes: {},
};

export enum reducerActionTypes {
  PUSH_NOTIFICATION,
  POP_NOTIFICATION,
}

// Partial makes all fields optional
export interface ReducerAction {
  type: reducerActionTypes;
  body: NotificationAction;
}

export const notificationStateReducer = (
  filterState: NotificationContainerState,
  action: ReducerAction
) => {
  const uuid = uuidv4();
  switch (action.type) {
    case reducerActionTypes.PUSH_NOTIFICATION:
      return {
        ...filterState,
        [action.body.type]: {
          ...filterState.successes,
          [uuid]: {
            message: action.body.message,
            duration: action.body.duration,
            suffix: action.body.suffix,
          },
        },
      };
    case reducerActionTypes.POP_NOTIFICATION:
      const typeUpdate = {
        ...filterState[action.body.type],
      };
      delete typeUpdate[action.body.uuid];
      return {
        ...filterState,
        [action.body.type]: typeUpdate,
      };
  }
};

export const NotificationStateContext =
  createContext<NotificationContainerState>(null);
export const NotificationStateDispatchContext =
  createContext<React.Dispatch<ReducerAction>>(null);

export const getDispatchFunctions = (dispatch, duration) => {
  return {
    dispatchSuccessNotification: (message: string, suffix: any = undefined) => {
      dispatch({
        type: reducerActionTypes.PUSH_NOTIFICATION,
        body: {
          type: NotificationTypes.SUCCESSES,
          message: message,
          duration: duration,
          suffix,
        },
      });
    },
    dispatchErrorNotification: (message: string, suffix: any = undefined) => {
      dispatch({
        type: reducerActionTypes.PUSH_NOTIFICATION,
        body: {
          type: NotificationTypes.ERRORS,
          message: message,
          duration: duration,
          suffix,
        },
      });
    },
    dispatchInfoNotification: (message: string, suffix: any = undefined) => {
      dispatch({
        type: reducerActionTypes.PUSH_NOTIFICATION,
        body: {
          type: NotificationTypes.ERRORS,
          message: message,
          duration: duration,
          suffix,
        },
      });
    },
  };
};

export interface NotificationContainerProps {
  className?: string;
}

export const NotificationContainer = ({
  className,
}: NotificationContainerProps) => {
  const { errors, infos, successes } = useContext(NotificationStateContext);
  const dispatchNotification = useContext(NotificationStateDispatchContext);

  const closeNotification = (type: NotificationTypes, uuid) => {
    dispatchNotification({
      type: reducerActionTypes.POP_NOTIFICATION,
      body: {
        type: type,
        uuid: uuid,
      },
    });
  };

  return (
    <div className={className}>
      <NotificationGroup className={styles["note-group"]}>
        {Object.keys(errors).map((key) => {
          setTimeout(() => {
            closeNotification(NotificationTypes.ERRORS, key);
          }, errors[key].duration);

          return (
            <Notification
              key={key}
              className={styles["notification-body"]}
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => {
                closeNotification(NotificationTypes.ERRORS, key);
              }}
            >
              <span>
                <span>{errors[key].message}</span>
                {errors[key].suffix}
              </span>
            </Notification>
          );
        })}
        {Object.keys(successes).map((key) => {
          setTimeout(() => {
            closeNotification(NotificationTypes.SUCCESSES, key);
          }, successes[key].duration);

          return (
            <Notification
              key={key}
              className={styles["success-note"]}
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => {
                closeNotification(NotificationTypes.SUCCESSES, key);
              }}
            >
              <span>
                <span>{successes[key].message}</span>
                {successes[key].suffix}
              </span>
            </Notification>
          );
        })}
        {Object.keys(infos).map((s) => {
          setTimeout(() => {
            closeNotification(NotificationTypes.INFOS, s);
          }, infos[s].duration);

          return (
            <Notification
              key={s}
              className={styles["notification-body"]}
              type={{
                style: "info",
                icon: true,
              }}
              closable={true}
              onClose={() => {
                closeNotification(NotificationTypes.INFOS, s);
              }}
            >
              <span>
                <span>{infos[s].message}</span>
                {infos[s].suffix}
              </span>
            </Notification>
          );
        })}
      </NotificationGroup>
    </div>
  );
};
