import { BookmarkIcon } from "@/assets/BookmarkIcon";
import styles from "@/components/styles/bookmark-button.module.scss";

export interface BookmarkButtonProps {
  favorited: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const BookmarkButton = ({
  favorited,
  disabled = false,
  onClick = () => {},
}: BookmarkButtonProps) => {
  return (
    <div
      className={
        disabled
          ? styles["bookmark-container"]
          : styles["bookmark-container-enabled"]
      }
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      <BookmarkIcon filled={favorited} className={styles["favorites-icon"]} />
    </div>
  );
};
