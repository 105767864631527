import "/node_modules/flag-icons/css/flag-icons.min.css";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { useContext } from "react";
import {
  NotificationStateDispatchContext,
  getDispatchFunctions,
} from "@/components/NotificationContext";
import styles from "@/components/styles/market-selector.module.scss";
import {
  FilterStateContext,
  FilterStateDispatchContext,
  reducerActionTypes,
} from "@/components/filters/FilterStateContext";
import { useIsMobile } from "@/components/util/mobile-utils";

interface MarketSelectorProps {
  fillMode?: "flat" | "outline";
}

export const COUNTRIES = {
  US: {
    flag: "fi-us",
    domain: "amazon.com",
  },
  DE: {
    flag: "fi-de",
    domain: "amazon.de",
  },
  UK: {
    flag: "fi-gb",
    domain: "amazon.co.uk",
  },
  CA: {
    flag: "fi-ca",
    domain: "amazon.ca",
  },
};

const MarketSelector = ({ fillMode = "flat" }: MarketSelectorProps) => {
  const isMobile = useIsMobile();
  const filterState = useContext(FilterStateContext);
  const dispatch = useContext(FilterStateDispatchContext);
  const dispatchNotification = useContext(NotificationStateDispatchContext);
  const { dispatchSuccessNotification } = getDispatchFunctions(
    dispatchNotification,
    6000
  );

  const fillModes = {
    flat: "country-dropdown-flat",
    outline: "country-dropdown-outline",
  };

  const handleChange = (event: DropDownListChangeEvent) => {
    dispatch({
      type: reducerActionTypes.UPDATE_MARKETPLACE,
      body: {
        marketplace: event.value,
      },
    });

    dispatchSuccessNotification(`Marketplace Updated To ${event.value}`);
  };

  const displayValue = (
    <>
      {isMobile ? (
        <span>
          <span className={styles["country-text"]}>
            <span
              className={`fi ${COUNTRIES[filterState.marketplace].flag}`}
            ></span>{" "}
            {filterState.marketplace}
          </span>
        </span>
      ) : (
        <span>
          <span className={styles["country-text"]}>
            Market: {filterState.marketplace}
          </span>
          <span
            className={`fi ${COUNTRIES[filterState.marketplace].flag}`}
          ></span>
        </span>
      )}
    </>
  );

  return (
    <DropDownList
      data={Object.keys(COUNTRIES)}
      fillMode={fillMode}
      value={displayValue}
      className={styles[fillModes[fillMode]]}
      onChange={handleChange}
    />
  );
};

export default MarketSelector;
