import { createContext, Dispatch, SetStateAction } from "react";
import { AdminCustomListType } from "@/components/adminCustomList/FormAdminCustomList";

interface ICustomListContext {
  tabIndex: number;
  selectedList: AdminCustomListType;

  setTabIndex: Dispatch<SetStateAction<number>>;
  setSelectedList: Dispatch<SetStateAction<AdminCustomListType>>;
}

export const CustomListContext = createContext<ICustomListContext>({
  tabIndex: 0,
  setTabIndex: () => {},
  selectedList: null,
  setSelectedList: () => {},
});
