import styles from "./styles/pagecontainer.module.scss";
import Auth from "./Auth";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import * as svgIcons from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import Image from "next/image";
import Head from "next/head";
import Link from "next/link";
import { useSession } from "next-auth/react";
import ProfileButton from "@/components/ProfileButton";
import MarketSelector from "@/components/MarketSelector";
import { FavoritesButton } from "@/components/favorites/FavoritesButton";
import { FeedbackButton } from "@/components/FeedbackButton";
import { MobileNavigationPane } from "@/components/mobile/MobileNavigationPane";
import { NotificationContainer } from "@/components/NotificationContext";

function PageContainer({ children }) {
  const [navPaneVisible, setNavPaneVisible] = useState<boolean>(false);
  const { data: session } = useSession();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [IsAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    // This is all logic repeated in custom hooks
    // This is here because having a mismatch in the number of hooks
    // on this component depending on mobile/not mobile was causeing
    // some nextjs errors
    setIsMobile(window.innerWidth <= 1026);
    setIsAdmin(session?.user?.groups?.includes("admin"));
  }, []);

  const updateMobileNavigationVisibility = () => {
    setNavPaneVisible(true);
  };

  const closeMobileNavigationVisibility = () => {
    setNavPaneVisible(false);
  };
  return (
    <Auth>
      <Head>
        <title>Thrasio Affiliate Portal</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans&display=swap"
          rel="stylesheet"
        />
      </Head>
      <NotificationContainer className={styles["note-container"]} />
      <MobileNavigationPane
        visible={navPaneVisible}
        onClose={closeMobileNavigationVisibility}
      />
      {isMobile ? (
        <div>
          <div className={styles["mobile-top-bar"]}></div>
          <AppBar id="app" className={styles["mobile-body"]}>
            <AppBarSection>
              <Button
                fillMode="flat"
                onClick={updateMobileNavigationVisibility}
                className={styles["mobile-hamburger-nav-btn"]}
                iconClass={styles["mobile-btn-icon"]}
              >
                <SvgIcon icon={svgIcons.menuIcon} size="xlarge" />
              </Button>
            </AppBarSection>
            <AppBarSection>
              <Link href="/">
                <Image
                  alt="Thrasio Logo"
                  priority={false}
                  src="/LittleThrasioLogo.png"
                  width={41}
                  height={38}
                />
              </Link>
            </AppBarSection>
            <AppBarSection>
              <FavoritesButton light={true} />
            </AppBarSection>
          </AppBar>
        </div>
      ) : (
        <AppBar id="app" className={styles["main-body"]}>
          <AppBarSection>
            <Link href="/">
              {/* https://thrasio.atlassian.net/browse/RD-983 */}
              {/* eslint-disable no-inline-styles/no-inline-styles */}
              <div
                style={{
                  position: "relative",
                  width: "220px",
                  height: "30px",
                }}
              >
                {/* https://thrasio.atlassian.net/browse/RD-983 */}
                {/* eslint-enable no-inline-styles/no-inline-styles */}
                <Image
                  alt="Thrasio Logo"
                  priority={false}
                  src="/ThrasioLogo.png"
                  layout="fill"
                  objectFit="cover"
                />
              </div>
            </Link>
          </AppBarSection>

          <AppBarSection></AppBarSection>
          {IsAdmin && (
            <AppBarSection>
              <label className={styles["admin-sign"]}>Admin Panel</label>
            </AppBarSection>
          )}
          <AppBarSection>
            <div className={styles["page-btn-group-right"]}>
              <FeedbackButton />
            </div>
          </AppBarSection>
          <AppBarSpacer />
          {/* https://thrasio.atlassian.net/browse/RD-983 */}
          {/* eslint-disable no-inline-styles/no-inline-styles */}
          <AppBarSpacer style={{ width: 4 }} />

          {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
          <AppBarSection style={{ marginRight: "15px" }}>
            <MarketSelector />
            <FavoritesButton />
            <ProfileButton />
          </AppBarSection>
        </AppBar>
      )}
      {children}
    </Auth>
  );
}

export default PageContainer;
