import { Button } from "@progress/kendo-react-buttons";
import * as svgIcons from "@progress/kendo-svg-icons";
import styles from "@/components/styles/product-catalog-pager.module.scss";

export interface ProductCatalogPageChangeEvent {
  pageNumber: number;
}

interface ProductCatalogPagerProps {
  total: number;
  pageSize: number;
  pageNumber: number;
  centerPageNumber?: boolean;
  onPageChange: (event: ProductCatalogPageChangeEvent) => void;
}

const ProductCatalogPager = ({
  total,
  pageSize,
  pageNumber,
  onPageChange,
  centerPageNumber = false,
}: ProductCatalogPagerProps) => {
  const onPageRight = () => {
    onPageChange({
      pageNumber: pageNumber + 1,
    });
  };

  const onPageLeft = () => {
    onPageChange({
      pageNumber: pageNumber - 1,
    });
  };

  const minItem = pageSize * pageNumber;
  const maxItem = pageSize * (pageNumber + 1);
  return (
    <div className={styles["page-container"]}>
      {!centerPageNumber && (
        <span className={styles["item-count"]}>
          {minItem}-{maxItem <= total ? maxItem : total} of {total}
        </span>
      )}
      <span>
        <Button
          className={styles["page-change-btn"]}
          disabled={minItem == 0}
          fillMode="outline"
          svgIcon={svgIcons.chevronLeftIcon}
          onClick={onPageLeft}
        />
        {centerPageNumber && (
          <span className={styles["center-item-count"]}>
            {minItem}-{maxItem <= total ? maxItem : total} of {total}
          </span>
        )}
        <Button
          className={styles["page-change-btn"]}
          disabled={maxItem >= total}
          fillMode="outline"
          svgIcon={svgIcons.chevronRightIcon}
          onClick={onPageRight}
        />
      </span>
    </div>
  );
};

export default ProductCatalogPager;
