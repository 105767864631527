import { createContext, Dispatch, SetStateAction } from "react";
import { FavoritesListType } from "@/components/favorites/FavoritesListCard";

interface IFavoritesListContext {
  tabIndex: number;
  favoriteList: FavoritesListType;

  setTabIndex: Dispatch<SetStateAction<number>>;
  setFavoriteList: Dispatch<SetStateAction<FavoritesListType>>;
}

export const FavoritesListContext = createContext<IFavoritesListContext>({
  tabIndex: 0,
  setTabIndex: () => {},
  favoriteList: null,
  setFavoriteList: () => {},
});
