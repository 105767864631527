import { SvgIcon } from "@progress/kendo-react-common";
import * as svgIcons from "@progress/kendo-svg-icons";
import { Error } from "@progress/kendo-react-labels";
import { useState } from "react";
import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { ShadowBox } from "@/components/favorites/ShadowBox";
import styles from "@/components/styles/create-new-list-box.module.scss";
import "@fortawesome/fontawesome-free/css/all.css";

export interface CreateNewListBoxProps {
  onSubmit: (title: string) => void;
  existingLists: string[];
}

export const CreateNewListBox = ({
  onSubmit,
  existingLists,
}: CreateNewListBoxProps) => {
  const [textValue, setTextValue] = useState<string>("");
  const [creating, setCreating] = useState<boolean>(false);
  const [nameInvalidMessage, setNameInvalidMessage] = useState<string>("");
  const [nameTextValid, setNameTextValid] = useState<boolean>(true);

  const isNameValid = (name): boolean => {
    if (!name) {
      setNameInvalidMessage("Name cannot be blank");
      return false;
    } else if (existingLists.indexOf(name) > -1) {
      setNameInvalidMessage("You already have a list with that name");
      return false;
    }

    setNameInvalidMessage("");
    return true;
  };

  const onPlusClick = () => {
    setCreating(true);
  };

  const onSubmitClicked = () => {
    setCreating(false);
    onSubmit(textValue);
    setTextValue("");
  };

  const onTextBoxChange = (e: TextBoxChangeEvent) => {
    setTextValue(e.target.value as string);
    setNameTextValid(isNameValid(e.value));
  };

  const onKeyDown = (e) => {
    // Enter key
    if (e.keyCode == 13 && textValue && nameTextValid) {
      onSubmitClicked();
    }
  };

  const outsideClick = () => {
    setTextValue("");
    setCreating(false);
  };

  return (
    <div className={styles["main-container"]}>
      {creating ? (
        <ShadowBox outsideClick={outsideClick}>
          <span className={styles["outermost-container"]}>
            <span className={styles["container"]}>
              <TextBox
                autoFocus={true}
                onChange={onTextBoxChange}
                placeholder="Start Typing Your Name"
                value={textValue}
                onKeyDown={onKeyDown}
              />
              <Button
                className={styles["submit-btn"]}
                onClick={onSubmitClicked}
                disabled={nameTextValid ? false : true}
              >
                <i className="fas fa-chevron-right"></i>
              </Button>
            </span>
            {!nameTextValid && (
              <Error className={styles["error-label"]}>
                {nameInvalidMessage}
              </Error>
            )}
          </span>
        </ShadowBox>
      ) : (
        <span className={styles["container-create-new"]} onClick={onPlusClick}>
          <SvgIcon
            className={styles["plus-icon"]}
            icon={svgIcons.plusIcon}
            size="xxxlarge"
          />
          <span className={styles["create-new-text"]}>
            <b>Create New List</b>
          </span>
        </span>
      )}
    </div>
  );
};
