import { useState } from "react";
import { SvgIcon } from "@progress/kendo-react-common";
import * as svgIcons from "@progress/kendo-svg-icons";
import styles from "@/components/styles/deal-tag.module.scss";
import { DEAL_TYPES_MAP } from "@/utils/constants";

export interface DealTagProps {
  label: string;
  type: string;
  promoCode: string;
}

export const DealTag = ({ type, promoCode }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const getClass = () => {
    switch (type) {
      case "LIGHTNING_DEAL":
        return styles["lightning"];
        break;
      case "COUPON":
        return styles["coupon"];
        break;
      case "PED":
        return styles["ped"];
        break;
      case "7_DAY_DEAL":
        return styles["seven-day"];
        break;
      case "TOP_DEAL":
        return styles["top-deal"];
        break;
      case "DEAL_OF_THE_DAY":
        return styles["deal-of-the-day"];
        break;
      case "PROMO_CODE":
        return styles["promo-code"];
        break;
      case "PROMO_CODE_EXCLUSIVE":
        return styles["promo-code"];
      default:
        return styles["coupon"];
    }
  };

  const isPromo = () => {
    return type === "PROMO_CODE" || type === "PROMO_CODE_EXCLUSIVE";
  };

  const getTagText = () => {
    if (copied) return "Copied Code";
    return isPromo()
      ? `${DEAL_TYPES_MAP[type]}: ${promoCode}`
      : DEAL_TYPES_MAP[type];
  };

  const onClick = () => {
    if (isPromo()) {
      navigator.clipboard.writeText(promoCode);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    }
  };

  const tag = <b>{getTagText()}</b>;

  return (
    <div className={getClass()} onClick={onClick}>
      {tag}
      <SvgIcon
        className={
          isPromo() && !copied ? styles["copy-icon"] : styles["hidden"]
        }
        icon={svgIcons.copyIcon}
        size="large"
      />
    </div>
  );
};
