import { ReactElement } from "react";
import { ShadowBox } from "@/components/favorites/ShadowBox";
import styles from "@/components/styles/add-to-list-box.module.scss";
import { BookmarkButton } from "@/components/favorites/BookmarkButton";

export interface AddToListBoxProps {
  id: string;
  favorited: boolean;
  listName: string;
  totalProducts: number;
  children?: ReactElement<any, any> | ReactElement<any, any>[];
  shared?: boolean;
  disableCheckClick?: boolean;
  onClick?: (id: string, value: boolean) => void;
}

export const AddToListBox = ({
  id,
  favorited,
  listName,
  totalProducts,
  children,
  disableCheckClick = false,
  onClick,
}: AddToListBoxProps) => {
  return (
    <ShadowBox>
      <span
        className={
          styles[
            disableCheckClick ? "flex-container-default" : "flex-container"
          ]
        }
      >
        <span className={styles["left-side"]}>
          <span className={styles["bookmark-btn-container"]}>
            <BookmarkButton
              favorited={favorited}
              disabled={disableCheckClick}
              onClick={() => {
                if (!disableCheckClick && onClick) onClick(id, !favorited);
              }}
            />
          </span>
          <span className={styles["title-container"]}>
            <span className={styles["title"]}>
              <b>{listName}</b>
            </span>
            <span
              className={styles["total-items"]}
            >{`${totalProducts} Products`}</span>
          </span>
        </span>
        {children}
      </span>
    </ShadowBox>
  );
};
