import { Card, CardBody, CardImage } from "@progress/kendo-react-layout";
import { useContext } from "react";
import styles from "@/components/styles/product-catalog-card.module.scss";
import StarRating from "@/components/productCatalog/StarRating";
import { COUNTRIES } from "@/components/MarketSelector";
import { DealTag } from "@/components/deals/DealTag";
import {
  numberToFormattedString,
  isDefinedAndNonNull,
} from "@/components/util/object-util";
import { Badge } from "@/components/productCatalog/Badge";
import { reportEventMixpanel } from "@/platform/Analytics";
import { FilterStateContext } from "@/components/filters/FilterStateContext";
import { PageContext } from "@/components/PageContext";
import { CustomListContext } from "@/components/CustomListContext";
import { FavoritesListContext } from "@/components/FavoritesListContext";
import {
  CUSTOM_LIST_PAGE_NAME,
  SPECIFIC_FAVORITES_LIST_PAGE_NAME,
} from "@/utils/constants";
import { getTabName } from "@/components/util/custom-list-util";
import { BookmarkButton } from "@/components/favorites/BookmarkButton";
import { getDateAndTime } from "@/components/util/date-util";

export interface ProductCatalogCardProps {
  id: string;
  asin: string;
  rating: number;
  ratingCount: number;
  image: string;
  productName: string;
  price: number;
  hasVariants: boolean;
  dealPrice?: number;
  discount?: number;
  dealType?: string;
  promoCode?: string;
  marketplace: string;
  startDate?: Date;
  endDate?: Date;
  favorited?: boolean;
  addRemoveBtnClick: (asin: string, favorited: boolean) => void;
}

export const getDaysRemaining = (endDate: Date) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const today = new Date();

  const daysRemaining = Math.round(
    (endDate.getTime() - today.getTime()) / oneDay
  );
  return daysRemaining > 0 ? daysRemaining : 0;
};

const ProductCatalogCard = ({
  id,
  asin,
  rating,
  ratingCount,
  image,
  productName,
  price,
  hasVariants,
  dealPrice,
  discount,
  dealType,
  promoCode,
  marketplace,
  startDate,
  endDate,
  favorited = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRemoveBtnClick,
}: ProductCatalogCardProps) => {
  const filterState = useContext(FilterStateContext);
  const pageState = useContext(PageContext);
  const customListState = useContext(CustomListContext);
  const favoritesListState = useContext(FavoritesListContext);

  const discountPercentage = discount !== undefined ? discount * 100 : 0;

  const getPaddedPrice = (p) => {
    if (!isDefinedAndNonNull(price)) {
      return "";
    }
    return `$${numberToFormattedString(p)}`;
  };

  const amazonLink = `https://www.${COUNTRIES[marketplace].domain}/dp/${asin}/`;

  const productClick = () => {
    let customListTab = null;
    let customListName = null;
    let favoritesListTab = null;
    let favoritesListName = null;
    if (pageState === CUSTOM_LIST_PAGE_NAME) {
      customListTab = getTabName(customListState.tabIndex);
      customListName = customListState.selectedList.name;
    } else if (pageState === SPECIFIC_FAVORITES_LIST_PAGE_NAME) {
      favoritesListTab = getTabName(favoritesListState.tabIndex);
      favoritesListName = favoritesListState.favoriteList.name;
    }
    reportEventMixpanel({
      event: "Product Click",
      properties: {
        asin: asin,
        marketplace: marketplace,
        price: price,
        page: pageState,
        "deal price": dealPrice,
        "discount amount": discount,
        "days until deal ends": getDaysRemaining(endDate),
        "rating count": ratingCount,
        "star rating": rating,
        "page number": filterState.pageNumber,
        "search term filter": filterState.productText,
        "category filter": filterState.categories,
        "price min filter": filterState.priceMin,
        "price max filter": filterState.priceMax,
        "rating filter": filterState.rating,
        "deal type filter": filterState.dealType,
        "sort filter": filterState.sorting,
        "deal date filter start": filterState.startDate,
        "deal date filter end": filterState.endDate,
        "min days remaining on deal": filterState.minDealDays,
        "max days remaining on deal": filterState.maxDealDays,
        "custom list tab": customListTab,
        "custom list name": customListName,
        "favorites list tab": favoritesListTab,
        "favorites list name": favoritesListName,
      },
    });
  };

  const onBookmarkClick = async () => {
    addRemoveBtnClick(id, favorited);
  };

  return (
    <Card type="default" className={styles["catalog-card"]}>
      <div className={styles["affiliate-link-btn-a"]}>
        <a
          className={styles["image-container"]}
          href={amazonLink}
          target="_blank"
          rel="noreferrer"
          onClick={productClick}
        >
          <CardImage
            src={image ? image : "/image_unavailable.jpg"}
            className={styles["catalog-product-image"]}
          />
        </a>
        {
          <div className={styles["bookmark-btn"]}>
            <BookmarkButton favorited={favorited} onClick={onBookmarkClick} />
          </div>
        }
        {hasVariants && (
          <div className={styles["type-tag"]}>
            <Badge label={"Multiple Types"} />
          </div>
        )}
        {dealType && (
          <div className={styles["deal-tag"]}>
            <DealTag type={dealType} promoCode={promoCode || ""} />
          </div>
        )}
      </div>
      <CardBody className={styles["catalog-card-body"]}>
        <a
          className={styles["product-name"]}
          href={amazonLink}
          target="_blank"
          rel="noreferrer"
          onClick={productClick}
        >
          <b>{productName}</b>
        </a>
        <StarRating rating={rating} ratingCount={ratingCount} />
        {discount !== undefined && (
          <div className={styles["date-container"]}>
            <span key="0" className={styles["days-remaining"]}>
              {getDaysRemaining(endDate)} Days Until Deal Ends
            </span>
            <div className={styles["date-time-container"]}>
              <span className={styles["date-time-prefix"]}>
                <span className={styles["deal-date-time"]}>
                  <b>Start:</b>
                </span>
                <span className={styles["deal-date-time"]}>
                  <b>End:</b>
                </span>
              </span>
              <span className={styles["date-time-line"]}>
                <span key="start" className={styles["deal-date-time"]}>
                  {getDateAndTime(startDate)}
                </span>
                <span key="end" className={styles["deal-date-time"]}>
                  {getDateAndTime(endDate)}
                </span>
              </span>
            </div>
          </div>
        )}
        <div className={styles["price-container"]}>
          {discount !== undefined ? (
            <span>
              <div>
                <span className={styles["discount-label"]}>
                  -{numberToFormattedString(discountPercentage)}%
                </span>
                &ensp;
                <span className={styles["price-label"]}>
                  <b>{getPaddedPrice(dealPrice)}</b>
                </span>
                &ensp;
                <s className={styles["discounted-price-label"]}>${price}</s>
              </div>
            </span>
          ) : (
            <span>
              <div className={styles["price-label"]}>
                {getPaddedPrice(price)}
              </div>
            </span>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default ProductCatalogCard;
