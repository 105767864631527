import { ReactElement, useEffect, useRef } from "react";
import styles from "@/components/styles/shadow-box.module.scss";

export interface ShadowBoxProps {
  outsideClick?: () => void;
  children: ReactElement<any, any> | ReactElement<any, any>[];
}

export const ShadowBox = ({
  outsideClick = () => {},
  children,
}: ShadowBoxProps) => {
  const boxRef = useRef(null);

  const handleClickOutside = (e) => {
    if (boxRef.current && !boxRef.current.contains(e.target as Node)) {
      outsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles["box-container-parent"]} ref={boxRef}>
      <div className={styles["box-container"]}>{children}</div>
    </div>
  );
};
