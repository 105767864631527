import { TextBox, TextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { useContext, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { SvgIcon } from "@progress/kendo-react-common";
import * as svgIcons from "@progress/kendo-svg-icons";
import { Label } from "@progress/kendo-react-labels";
import styles from "@/components/styles/add-remove-product-dialog.module.scss";
import { AddToListBox } from "@/components/favorites/AddToListBox";
import { CreateNewListBox } from "@/components/favorites/CreateNewListBox";
import { updateFavoritesListProductsRequest } from "@/components/util/favorites-list-gql-util";
import {
  createFavoritesList,
  deleteProductFromFavoritesLists,
} from "@/components/util/favorites-list-gql-util";
import { getOrderedFavoritesLists } from "@/components/util/favorites-list-util";
import { FilterStateContext } from "@/components/filters/FilterStateContext";
import {
  NotificationStateDispatchContext,
  getDispatchFunctions,
} from "@/components/NotificationContext";
import { CustomWindowDialog } from "@/components/favorites/CustomWindowDialog";
import {
  reportFavoriteListCreated,
  reportFavoritedProduct,
} from "@/platform/Analytics";
import { ProductCatalogCardProps } from "@/components/productCatalog/ProductCatalogCard";
import { PageContext } from "@/components/PageContext";
import { LabelBorder } from "@/components/LabelBorder";
import { FavoritesListType } from "@/components/favorites/FavoritesListCard";

export interface AddRemoveProductDialogProps {
  visible: boolean;
  targetProduct: ProductCatalogCardProps;
  onSubmit: () => void;
  onClose: () => void;
}

export const AddRemoveProductDialog = ({
  visible,
  targetProduct,
  onSubmit,
  onClose,
}: AddRemoveProductDialogProps) => {
  const client = useApolloClient();
  const filterState = useContext(FilterStateContext);
  const page = useContext(PageContext);
  const dispatchNotification = useContext(NotificationStateDispatchContext);
  const [defaultList, setDefaultLIst] = useState<FavoritesListType>();
  const [lists, setLists] = useState([]);
  const [filterText, setFilterText] = useState<string>("");
  const [removingAll, setRemovingAll] = useState<boolean>(false);
  const { dispatchSuccessNotification, dispatchErrorNotification } =
    getDispatchFunctions(dispatchNotification, 6000);

  // these lists determine which lists are checked and which go in the request to udpate
  const [listsProductIsAddedTo, setListsProductIsAddedTo] = useState<string[]>(
    []
  );
  const [currentSelectedLists, setCurrentSelectedLists] = useState<string[]>(
    []
  );

  const createNewList = async (title: string) => {
    const { result, errors } = await createFavoritesList(client, {
      name: title,
      marketplace: filterState.marketplace,
    });

    if (errors.length > 0) {
      errors.map((e) => {
        dispatchErrorNotification(e);
      });
    } else {
      dispatchSuccessNotification("Successfully Created List");
      reportFavoriteListCreated(result.id, title);
      setLists([result, ...lists]);
    }
  };

  const callGetFavoritesLists = async () => {
    const results = await getOrderedFavoritesLists(
      client,
      filterState.marketplace
    );
    const defaultList = results.filter((l) => l.isDefault)[0];
    const resultIds = results
      .filter((l) => !l.isDefault)
      .filter((l) => isProductIdInList(targetProduct.id, l))
      .map((r) => r.id);
    setDefaultLIst(defaultList);
    setLists(results.filter((l) => !l.isDefault));

    setListsProductIsAddedTo(resultIds);
    // add the default id to be automatically selected
    setCurrentSelectedLists(resultIds);
  };

  const onFilterTextChange = (e: TextBoxChangeEvent) => {
    setFilterText(e.value as string);
  };

  useEffect(() => {
    if (visible) {
      callGetFavoritesLists();
    }
  }, [visible]);

  // Returns true if the product id is in list's products
  const isProductIdInList = (id: string, list) => {
    const productIdsInList: string[] = list.products.map((l) => l.id);
    return productIdsInList.indexOf(id) > -1;
  };

  // Returns true if a list has been selected/unselected
  const areListsSame = (a: string[], b: string[]): boolean => {
    if (a.length != b.length) {
      return false;
    } else {
      a = [...a].sort();
      b = [...b].sort();

      for (let i = 0; i < a.length; i++) {
        if (a[i] != b[i]) {
          return false;
        }
      }
    }
    return true;
  };

  const onCheckClick = (id, value) => {
    if (value) {
      setCurrentSelectedLists([...currentSelectedLists, id]);
    } else {
      const i = currentSelectedLists.indexOf(id);
      let listUpdate = [...currentSelectedLists];
      listUpdate.splice(i, 1);
      setCurrentSelectedLists(listUpdate);
    }
  };

  const matchesSearch = (s: string): boolean => {
    return s.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
  };

  const sendFavoritedProductMixpanelEvents = () => {
    const newListsSavedTo: string[] = currentSelectedLists.filter(
      (id) => listsProductIsAddedTo.indexOf(id) == -1
    );

    newListsSavedTo.map((listId) => {
      const list = lists.find((list) => list.id == listId);
      reportFavoritedProduct(list.name, targetProduct, page);
    });
  };

  const onSubmitClicked = async () => {
    const { errors, results } = await updateFavoritesListProductsRequest(
      client,
      {
        product: targetProduct.id,
        favoritesLists: currentSelectedLists,
      }
    );
    if (errors.length > 0) {
      errors.map((e) => {
        dispatchErrorNotification(e);
      });
    } else {
      if (results.length == 1) {
        dispatchSuccessNotification(
          `Added product and any related deals to "${results[0].name}" list.`,
          <a
            className={styles["notification-link"]}
            href={`/favorites/${results[0].id}`}
          >
            View List
          </a>
        );
      } else {
        dispatchSuccessNotification(
          "Added product and any related deals to the selected lists.",
          <a className={styles["notification-link"]} href="/favorites">
            View Lists
          </a>
        );
      }
      sendFavoritedProductMixpanelEvents();
    }
    onSubmit();
  };

  const onRemoveAllSubmit = async () => {
    const { errors } = await deleteProductFromFavoritesLists(client, {
      product: targetProduct.id,
    });
    if (errors) {
      errors.map((e) => {
        dispatchErrorNotification(e);
      });
      onClose();
    } else {
      dispatchSuccessNotification("Removed Product From All Lists");
      onSubmit();
    }
    setRemovingAll(false);
  };

  const onRemoveAllClicked = async () => {
    if (!removingAll) setRemovingAll(true);
  };

  const onRemoveAllCancelClicked = async () => {
    if (removingAll) setRemovingAll(false);
  };

  const listsAreTheSame = areListsSame(
    listsProductIsAddedTo,
    currentSelectedLists
  );

  if (!visible) return <div hidden={true}></div>;
  return (
    <CustomWindowDialog title={"Add/Remove Product & Deals"} onClose={onClose}>
      <div className={styles["dialog-container"]}>
        <div className={styles["dialog-body"]}>
          <div className={styles["add-remove-item-base"]}>
            <TextBox
              placeholder="Start Typing To Search"
              className={styles["search-box"]}
              onChange={onFilterTextChange}
              value={filterText}
              prefix={() => {
                return (
                  <div className={styles["searchbox-prefix"]}>
                    <SvgIcon size="xlarge" icon={svgIcons.searchIcon} />
                  </div>
                );
              }}
              suffix={() => {
                if (filterText) {
                  return (
                    <Button
                      svgIcon={svgIcons.xIcon}
                      fillMode="flat"
                      onClick={() => setFilterText("")}
                    />
                  );
                }
              }}
            />
          </div>
          <LabelBorder
            label="Default List"
            className={styles["label-border"]}
          />
          {removingAll && (
            <div className={styles["label-container"]}>
              <SvgIcon icon={svgIcons.infoSolidIcon} size="xlarge" />
              <Label
                editorId={defaultList.id}
                className={styles["cancel-remove-hint"]}
              >
                Click &quot;Cancel&quot; To Undo
              </Label>
            </div>
          )}
          {defaultList && (
            <AddToListBox
              key={defaultList.id}
              id={defaultList.id}
              favorited={!removingAll}
              listName={defaultList.name}
              totalProducts={
                defaultList.products.filter((l) => l.isHero).length
              }
              disableCheckClick={true}
            >
              {removingAll ? (
                <Button
                  fillMode="outline"
                  themeColor="primary"
                  onClick={onRemoveAllCancelClicked}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  fillMode="outline"
                  themeColor="error"
                  onClick={onRemoveAllClicked}
                >
                  Unfavorite
                </Button>
              )}
            </AddToListBox>
          )}
          <LabelBorder label="My Lists" className={styles["label-border"]} />
          <CreateNewListBox
            onSubmit={createNewList}
            existingLists={lists.map((l) => l.name)}
          />
          {lists.map((l) => {
            if (matchesSearch(l.name)) {
              return (
                <AddToListBox
                  key={l.id}
                  id={l.id}
                  favorited={
                    removingAll
                      ? false
                      : currentSelectedLists.indexOf(l.id) > -1
                  }
                  listName={l.name}
                  totalProducts={l.products.filter((l) => l.isHero).length}
                  onClick={onCheckClick}
                >
                  <span
                    className={styles["img-box"]}
                    hidden={l.products.length < 1}
                  >
                    {l.products
                      .filter((li) => li.isHero)
                      .map((p) => p.image)
                      .map((i, index) => {
                        if (index <= 3) {
                          return (
                            <img
                              key={index}
                              className={styles["favorites-img"]}
                              src={i}
                              alt={"product image"}
                            />
                          );
                        }
                      })}
                  </span>
                </AddToListBox>
              );
            }
          })}
        </div>
        <div className={styles["dialog-footer"]}>
          <div className={styles["btn-container"]}>
            {removingAll ? (
              <Button
                className={styles["btn"]}
                fillMode="solid"
                themeColor="error"
                onClick={onRemoveAllSubmit}
              >
                Remove product from all lists
              </Button>
            ) : (
              <Button
                className={styles["btn"]}
                fillMode="solid"
                themeColor="primary"
                onClick={listsAreTheSame ? onClose : onSubmitClicked}
              >
                {listsAreTheSame ? "Close" : "Submit"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </CustomWindowDialog>
  );
};
