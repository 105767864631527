import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { faBookmark as filledBookmark } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.css";

export interface BookmarkIconProps {
  filled: boolean;
  className?: string;
}

export const BookmarkIcon = ({ filled, className }: BookmarkIconProps) => {
  return (
    <div>
      {filled ? (
        <FontAwesomeIcon icon={filledBookmark} className={className} />
      ) : (
        <FontAwesomeIcon icon={faBookmark} className={className} />
      )}
    </div>
  );
};
