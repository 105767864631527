import mixpanel from "mixpanel-browser";
import getConfig from "next/config";
import { getSession } from "next-auth/react";
import {
  getDaysRemaining,
  ProductCatalogCardProps,
} from "@/components/productCatalog/ProductCatalogCard";

export let INITIALIZED = false;

export default function initAnalytics() {
  const { publicRuntimeConfig } = getConfig();

  if (
    publicRuntimeConfig.mixpanelEnabled &&
    publicRuntimeConfig.mixpanelClientToken &&
    typeof window !== "undefined"
  ) {
    mixpanel.init(publicRuntimeConfig.mixpanelClientToken, {
      debug: publicRuntimeConfig.mixpanelDebugMode,
    });

    getSession().then((session) => {
      if (session) {
        const { user } = session;
        const user_id = user.id;
        if (user_id) {
          mixpanel.identify(user_id);
        }
      }
    });

    INITIALIZED = true;
  }
}

export const reportEventMixpanel = (args: {
  event: string;
  properties: object;
}) => {
  mixpanel.track(args.event, args.properties);
};

export const reportPageviewMixpanel = (
  pagename: string,
  marketplace: string,
  extra_properties?: object
) => {
  reportEventMixpanel({
    event: "Page View",
    properties: {
      page: pagename,
      marketplace: marketplace,
      ...extra_properties,
    },
  });
};

export const reportFavoritedProduct = (
  listName: string,
  product: ProductCatalogCardProps,
  page: string
) => {
  const endDate = new Date(product.endDate);
  const daysUntilDealEnds = getDaysRemaining(endDate);
  reportEventMixpanel({
    event: "Favorited Product",
    properties: {
      ASIN: product.asin,
      Price: product.price,
      "Deal Price": product.dealPrice,
      "Discount Amount": product.discount
        ? `${(product.discount * 100).toFixed(0)}%`
        : null,
      "Days Until Deal Ends": daysUntilDealEnds,
      "Rating Count": product.ratingCount,
      "Star Rating": product.rating,
      Marketplace: product.marketplace,
      Page: page,
      "List Saved To": listName,
    },
  });
};

export const reportFavoriteListCreated = (id, name) => {
  const todayString = new Date().toISOString();
  reportEventMixpanel({
    event: "Created a Favorites List",
    properties: {
      "List Name": name,
      "List Creation Date": todayString,
      "List ID": id,
    },
  });
};
