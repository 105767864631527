import Link from "next/link";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "@/components/styles/mobile-navigation-button.module.scss";

export interface MobileNavigationButtonProps {
  title: string;
  selected: boolean;
  icon: React.ReactNode;
  href: string;
  onClick: () => void;
}

export const MobileNavigationButton = ({
  title,
  selected,
  icon,
  href,
  onClick,
}: MobileNavigationButtonProps) => {
  return (
    <Link
      className={selected ? styles["link-btn-selected"] : styles["link-btn"]}
      href={href}
      onClick={onClick}
    >
      <span className={styles["btn-title"]}>
        {selected && icon}
        <span className={styles["title"]}>{title}</span>
      </span>
      <ChevronRightIcon className={styles["chevron-icon"]} />
    </Link>
  );
};
