export const getDateAndTime = (theDate: Date) => {
  const timezone = theDate
    .toLocaleTimeString("en-US", { timeZoneName: "short" })
    .split(" ")[2];
  const month = (theDate.getMonth() + 1).toString().padStart(2, "0");
  const day = theDate.getDate().toString().padStart(2, "0");
  const year = theDate.getFullYear();
  var hours = theDate.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  const hour = (hours ? hours : 12).toString().padStart(2, "0");
  const minutes = theDate.getMinutes().toString().padStart(2, "0");
  return `${month}/${day}/${year} | ${hour}:${minutes} ${ampm} (${timezone})`;
};
