import { getFavoritesListsRequest } from "@/components/util/favorites-list-gql-util";

export const getOrderedFavoritesLists = async (client, marketplace) => {
  const { results } = await getFavoritesListsRequest(client, {
    marketplace: marketplace,
  });
  const orderedList = [];
  const defaultListIdx = results.findIndex((list) => list.isDefault);
  if (results[defaultListIdx]) {
    orderedList.push(results[defaultListIdx]);
  }
  results.splice(defaultListIdx, 1);
  const sortedList = results.sort(
    (list1, list2) =>
      new Date(list2.createdOn).valueOf() - new Date(list1.createdOn).valueOf()
  );
  orderedList.push(...sortedList);
  return orderedList;
};
