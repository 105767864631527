import mixpanel from "mixpanel-browser";
import "@fortawesome/fontawesome-free/css/all.css";
import { Avatar } from "@progress/kendo-react-layout";
import { signOut } from "next-auth/react";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { useContext, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { SvgIcon } from "@progress/kendo-react-common";
import * as svgIcons from "@progress/kendo-svg-icons";
import { Loader } from "@progress/kendo-react-indicators";
import styles from "@/components/styles/profile-button.module.scss";
import { FilterStateContext } from "@/components/filters/FilterStateContext";
import { PageContext } from "@/components/PageContext";
import { reportEventMixpanel } from "@/platform/Analytics";
import { downloadProductCatalog } from "@/components/util/gql-util";

const ProfileButton = () => {
  const filterState = useContext(FilterStateContext);
  const pageState = useContext(PageContext);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const reportExportClick = () => {
    reportEventMixpanel({
      event: "Export Catalog",
      properties: { marketplace: filterState.marketplace, page: pageState },
    });
  };

  const onClick = async (e) => {
    switch (e.item) {
      case items[0]:
        reportExportClick();
        setLoading(true);
        downloadProductCatalog(client, filterState);
        setLoading(false);
        break;
      case items[1]:
        mixpanel.reset();
        await signOut();
        break;
    }
  };

  const items = [
    <div key={0} className={styles["dropdown-item"]}>
      Export Entire Catalog&ensp;
      {loading ? (
        <Loader size="small" type="infinite-spinner" />
      ) : (
        <SvgIcon
          className={styles["dropdown-icon"]}
          size="large"
          icon={svgIcons.exportIcon}
        />
      )}
    </div>,
    <div key={1} className={styles["dropdown-item"]}>
      Sign Out
    </div>,
  ];

  return (
    <Avatar
      type="icon"
      size="medium"
      fillMode="outline"
      className={styles["avatar"]}
    >
      <DropDownButton
        fillMode="flat"
        svgIcon={svgIcons.userIcon}
        items={items}
        onItemClick={onClick}
      />
    </Avatar>
  );
};

export default ProfileButton;
