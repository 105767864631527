import React, { useReducer, useState } from "react";

import "./app.css";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import "flag-icon-css/css/flag-icon.css";

import { ApolloProvider } from "@apollo/client";
import { useEffect } from "react";
import { useRouter } from "next/router";
import App from "next/app";
import { SessionProvider } from "next-auth/react";
import { useApollo } from "@/platform/apolloClient";
import PageContainer from "@/platform/PageContainer";
import initDatadogClient from "@/platform/datadogClient";
import initAnalytics from "@/platform/Analytics";
import {
  FilterStateContext,
  filterStateReducer,
  initialTopProductsFilterState,
  initialFilterState,
  initialDealsFilterState,
  FilterStateDispatchContext,
} from "@/components/filters/FilterStateContext";
import { CustomListContext } from "@/components/CustomListContext";
import { AdminCustomListType } from "@/components/adminCustomList/FormAdminCustomList";
import { FavoritesListContext } from "@/components/FavoritesListContext";
import { FavoritesListType } from "@/components/favorites/FavoritesListCard";
import {
  NotificationStateContext,
  NotificationStateDispatchContext,
  notificationStateReducer,
  initialNotificationState,
} from "@/components/NotificationContext";

initDatadogClient();

const AppLayout = ({ Component, pageProps }) => {
  const router = useRouter();

  const getInitialFilterState = () => {
    switch (router.asPath.split("/")[1]) {
      case "topproducts":
        return initialTopProductsFilterState;
      case "customlists":
        return initialFilterState;
      case "allproducts":
        return initialFilterState;
      case "deals":
        return initialDealsFilterState;
      case "favorites":
        return initialFilterState;
      default:
        return initialTopProductsFilterState;
    }
  };

  const [filterState, filterStateDispatch] = useReducer(
    filterStateReducer,
    getInitialFilterState()
  );
  const [notificationState, notificationStateDispatch] = useReducer(
    notificationStateReducer,
    initialNotificationState
  );

  const [customListTabIndex, setCustomListTabIndex] = useState<number>(0);
  const [selectedCustomList, setSelectedCustomList] =
    useState<AdminCustomListType>(null);
  const [favoritesListTabIndex, setFavoritesListTabIndex] = useState<number>(0);
  const [selectedFavoritesList, setSelectedFavoritesList] =
    useState<FavoritesListType>(null);
  const customListValue = {
    tabIndex: customListTabIndex,
    setTabIndex: setCustomListTabIndex,
    selectedList: selectedCustomList,
    setSelectedList: setSelectedCustomList,
  };
  const favoritesListValue = {
    tabIndex: favoritesListTabIndex,
    setTabIndex: setFavoritesListTabIndex,
    favoriteList: selectedFavoritesList,
    setFavoriteList: setSelectedFavoritesList,
  };

  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    initAnalytics();

    if (router.pathname.search("auth/") > -1) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    const handler = () => {};
    router.events.on("routeChangeComplete", handler);
    return () => {
      router.events.off("routeChangeComplete", handler);
    };
  }, [router]);

  return (
    <SessionProvider session={pageProps.session}>
      {router.pathname.search("auth/") > -1 ? (
        <Component {...pageProps} />
      ) : (
        <ApolloProvider client={apolloClient}>
          <NotificationStateContext.Provider value={notificationState}>
            <NotificationStateDispatchContext.Provider
              value={notificationStateDispatch}
            >
              <FilterStateContext.Provider value={filterState}>
                <FilterStateDispatchContext.Provider
                  value={filterStateDispatch}
                >
                  <CustomListContext.Provider value={customListValue}>
                    <FavoritesListContext.Provider value={favoritesListValue}>
                      <PageContainer>
                        <Component {...pageProps} />
                      </PageContainer>
                    </FavoritesListContext.Provider>
                  </CustomListContext.Provider>
                </FilterStateDispatchContext.Provider>
              </FilterStateContext.Provider>
            </NotificationStateDispatchContext.Provider>
          </NotificationStateContext.Provider>
        </ApolloProvider>
      )}
    </SessionProvider>
  );
};

AppLayout.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default AppLayout;
