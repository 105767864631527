import { SvgIcon } from "@progress/kendo-react-common";
import * as svgIcons from "@progress/kendo-svg-icons";
import styles from "@/components/styles/star-rating.module.scss";

interface StarRatingProps {
  rating: number;
  ratingCount: number;
}

const StarRating = ({ rating, ratingCount }: StarRatingProps) => {
  const getShortenedReviewCount = (r) => {
    if (r / 1000000 > 1) {
      return `${(r / 1000000).toFixed(1)}M`;
    } else if (r / 1000 > 1) {
      return `${(r / 1000).toFixed(1)}K`;
    } else {
      return r;
    }
  };

  const filledStars = Math.floor(rating);
  const halfStars = Math.round(rating - filledStars);
  const emptyStars = 5 - halfStars - filledStars;
  return (
    <div className={styles["star-rating"]}>
      <span className={styles["star-container"]}>
        {Array.from(Array(filledStars), (item, index) => {
          return (
            <SvgIcon
              key={`filled-${index}`}
              size="large"
              themeColor="warning"
              icon={svgIcons.starIcon}
              className={styles["star"]}
            />
          );
        })}
        {halfStars > 0 && (
          <span className={styles["star-half-cont"]}>
            <SvgIcon
              className={styles["star-half-fill"]}
              size="large"
              themeColor="warning"
              icon={svgIcons.starIcon}
            />
            <SvgIcon
              className={styles["star-half-outline"]}
              size="large"
              themeColor="warning"
              icon={svgIcons.starOutlineIcon}
            />
          </span>
        )}
        {Array.from(Array(emptyStars), (item, index) => {
          return (
            <SvgIcon
              key={`empty-${index}`}
              size="large"
              themeColor="warning"
              icon={svgIcons.starOutlineIcon}
              className={styles["star"]}
            />
          );
        })}
      </span>
      <span className={styles["review-count"]}>
        {getShortenedReviewCount(ratingCount)}
      </span>
    </div>
  );
};

export default StarRating;
