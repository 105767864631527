import Link from "next/link";
import { useState } from "react";
import { BookmarkIcon } from "@/assets/BookmarkIcon";
import styles from "@/components/styles/favorites-button.module.scss";
import { useIsMobile } from "@/components/util/mobile-utils";

export interface FavoritesButtonProps {
  light?: boolean;
}

export const FavoritesButton = ({ light = false }: FavoritesButtonProps) => {
  const isMobile = useIsMobile();
  const url = new URL(document.URL);
  const pageURL = url.pathname.split("/")[1];
  const [hovering, setHovering] = useState<boolean>(false);

  const onMouseEnter = () => {
    setHovering(true);
  };

  const onMouseExit = () => {
    setHovering(false);
  };

  return (
    <Link
      href="/favorites"
      className={styles["link"]}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseExit}
    >
      <div
        className={
          hovering ? styles["btn-background-hover"] : styles["btn-background"]
        }
      ></div>
      <div className={styles["btn-body"]}>
        <BookmarkIcon
          filled={pageURL === "favorites" && isMobile ? true : false}
          className={light ? styles["dark-icon"] : styles["favorites-icon"]}
        />
      </div>
    </Link>
  );
};
