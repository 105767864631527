export const ACCESS_TOKEN = "ACCESS_TOKEN";

export const CATEGORIES = [
  "Arts, Crafts & Sewing",
  "Automotive Parts & Accessories",
  "Baby",
  "Baby Diapering, Care, & Other",
  "Beauty & Personal Care",
  "Bedding",
  "Cell Phones & Accessories",
  "Clothing, Shoes & Jewelry",
  "Electronics",
  "Garden & Outdoor",
  "Grocery & Gourmet Food",
  "Health, Household & Baby Care",
  "Home & Kitchen",
  "Home Decor, Kitchen, & Other",
  "Industrial & Scientific",
  "Musical Instruments",
  "Office Products",
  "Pet Supplies",
  "Sports & Outdoors",
  "Tools & Home Improvement",
  "Toys & Games",
];

export const DEAL_TYPES = [
  "7 Day Deal",
  "Coupon",
  "Deal of the Day",
  "Lightning",
  "PED",
  "Top Deal",
  "Promo Code",
  "Promo Code: Exclusive",
];

export const DEAL_TYPES_MAP = {
  "7_DAY_DEAL": "7 Day Deal",
  COUPON: "Coupon",
  DEAL_OF_THE_DAY: "Deal of the Day",
  LIGHTNING_DEAL: "Lightning",
  PED: "PED",
  TOP_DEAL: "Top Deal",
  PROMO_CODE: "Promo Code",
  PROMO_CODE_EXCLUSIVE: "Exclusive Code",
};

export const DEALS_PAGE_NAME = "Deals";
export const ALL_PRODUCTS_PAGE_NAME = "All Products";
export const TOP_PRODUCTS_PAGE_NAME = "Top Products";
export const CUSTOM_LIST_PAGE_NAME = "Custom Lists";
export const FAVORITES_LIST_PAGE_NAME = "Favorites";
export const SALES_REPORTS_PAGE = "Sales Reports";
export const SPECIFIC_FAVORITES_LIST_PAGE_NAME = "Favorites List";

export const CUSTOM_LIST_PRODUCTS_TAB = "Products";
export const CUSTOM_LIST_DEALS_TAB = "Deals";
export const CUSTOM_LIST_TABS = [
  CUSTOM_LIST_PRODUCTS_TAB,
  CUSTOM_LIST_DEALS_TAB,
];

export const DEFAULT_FAVORITES_LIST = "My Favorites";

// Errors
export const REFRESH_ERROR = "Failed to refresh token";
