import { buildApolloClientCache } from "./cachePolicies";
import { useMemo } from "react";
import { ApolloClient, HttpLink } from "@apollo/client";
import { getSession, signOut } from "next-auth/react";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";
import getConfig from "next/config";
import { setContext } from "@apollo/client/link/context";
import { ACCESS_TOKEN, REFRESH_ERROR } from "@/utils/constants";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient;
const { publicRuntimeConfig } = getConfig();

export interface ACCESS_TOKEN_TYPE {
  accessToken: string;
  idToken: string;
  expiration: string;
}

const httpLink = new HttpLink({
  uri: publicRuntimeConfig.graphqlApiEndpoint,
});

/*
 * This is executed with every request
 */
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token: ACCESS_TOKEN_TYPE = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
  const today = new Date();
  const expirationDate = new Date(token.expiration);

  if (today > expirationDate) {
    // token is expired
    const newToken = await getSession();
    if (newToken.error == REFRESH_ERROR) {
      await signOut({ redirect: true });
      return;
    }
    token = {
      accessToken: newToken.accessToken,
      idToken: newToken.idToken,
      expiration: newToken.accessExpires,
    };
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(token));
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.idToken}` : "",
    },
  };
});

function createApolloClient() {
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: buildApolloClientCache(),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state =
    pageProps && APOLLO_STATE_PROP_NAME in pageProps
      ? pageProps[APOLLO_STATE_PROP_NAME]
      : null;
  return useMemo(() => initializeApollo(state), [state]);
}
