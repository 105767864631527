import { Button } from "@progress/kendo-react-buttons";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import styles from "@/components/styles/feedback-button.module.scss";
import { useIsMobile } from "@/components/util/mobile-utils";

export const FeedbackButton = ({}) => {
  const isMobile = useIsMobile();
  return (
    <div>
      {isMobile ? (
        <Button fillMode="solid" className={styles["feedback-mobile-button"]}>
          <a
            href="https://forms.gle/9FhVMXTbgZo3pfMV7"
            target="_blank"
            rel="noreferrer"
            className={styles["feedback-mobile-a"]}
          >
            Feedback
          </a>
        </Button>
      ) : (
        <Button fillMode="solid" className={styles["feedback-btn-button"]}>
          <a
            href="https://forms.gle/9FhVMXTbgZo3pfMV7"
            target="_blank"
            rel="noreferrer"
            className={styles["feedback-btn-a"]}
          >
            Feedback&ensp;
            <OpenInNewIcon className={styles["dropdown-icon"]} />
          </a>
        </Button>
      )}
    </div>
  );
};
