import { ApolloClient, gql } from "@apollo/client";

export const updateFavoritesListProductsRequest = async (
  client: ApolloClient<object>,
  input
) => {
  const mutation = gql`
    mutation updateFavoritesListProducts(
      $input: UpdateFavoritesListProductInput!
    ) {
      updateFavoritesListProducts(input: $input) {
        errors
        message
        results {
          id
          name
        }
      }
    }
  `;
  const { data } = await client.mutate({
    mutation: mutation,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data.updateFavoritesListProducts;
};

export const getFavoritesListRequest = async (
  client: ApolloClient<object>,
  input
) => {
  const query = gql`
    query getFavoritesList($input: GetFavoritesListInput!) {
      getFavoritesList(input: $input) {
        result {
          id
          name
          description
          isDefault
          marketplace
          createdBy {
            id
          }
          shared
          products {
            asin
          }
        }
      }
    }
  `;
  const { data } = await client.query({
    query: query,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data;
};

export const getFavoritesListsRequest = async (
  client: ApolloClient<object>,
  input
) => {
  const query = gql`
    query getFavoritesLists($input: GetFavoritesListsInput!) {
      getFavoritesLists(input: $input) {
        results {
          id
          name
          description
          isDefault
          createdOn
          marketplace
          products {
            id
            image
            isHero
          }
        }
      }
    }
  `;
  const { data } = await client.query({
    query: query,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data.getFavoritesLists;
};

export const getFavoritesListDownloadRequest = async (
  client: ApolloClient<object>,
  input
) => {
  const query = gql`
    query getFavoritesList($input: GetFavoritesListInput!) {
      getFavoritesList(input: $input) {
        result {
          id
          name
          description
          isDefault
          createdOn
          marketplace
          products {
            id
            asin
          }
        }
      }
    }
  `;
  const { data } = await client.query({
    query: query,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data.getFavoritesList;
};

// Mutations

export const deleteProductFromFavoritesLists = async (
  client: ApolloClient<object>,
  input
) => {
  const mutation = gql`
    mutation deleteProductFromFavoritesLists(
      $input: DeleteFavoritesListProductInput!
    ) {
      deleteProductFromFavoritesLists(input: $input) {
        errors
        message
      }
    }
  `;
  const { data } = await client.mutate({
    mutation: mutation,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data;
};

export const deleteFavoritesList = async (
  client: ApolloClient<object>,
  input
) => {
  const mutation = gql`
    mutation deleteFavoritesList($input: DeleteFavoritesListInput!) {
      deleteFavoritesList(input: $input) {
        errors
        message
      }
    }
  `;
  const { data } = await client.mutate({
    mutation: mutation,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data.deleteFavoritesList;
};

export const createFavoritesList = async (
  client: ApolloClient<object>,
  input
) => {
  const mutation = gql`
    mutation createFavoritesList($input: CreateFavoritesListInput!) {
      createFavoritesList(input: $input) {
        errors
        message
        result {
          id
          name
          description
          isDefault
          products {
            id
            asin
            image
          }
        }
      }
    }
  `;

  const { data } = await client.mutate({
    mutation: mutation,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data.createFavoritesList;
};

export const updateFavoritesList = async (
  client: ApolloClient<object>,
  input
) => {
  const mutation = gql`
    mutation updateFavoritesList($input: UpdateFavoritesListInput!) {
      updateFavoritesList(input: $input) {
        errors
        message
        result {
          id
          name
          description
          isDefault
          products {
            id
            asin
            image
          }
        }
      }
    }
  `;

  const { data } = await client.mutate({
    mutation: mutation,
    variables: { input },
    fetchPolicy: "no-cache",
  });
  return data.updateFavoritesList;
};
