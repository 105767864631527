import { useEffect, useRef, useState } from "react";
import {
  DateRangePicker,
  DateRangePickerChangeEvent,
  DatePicker,
  MultiViewCalendarProps,
  MultiViewCalendar,
  SelectionRange,
  MultiViewCalendarChangeEvent,
} from "@progress/kendo-react-dateinputs";
import * as svgIcons from "@progress/kendo-svg-icons";
import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import { useIsMobile } from "@/components/util/mobile-utils";
import styles from "@/components/styles/single-input-date-range-picker.module.scss";

export interface SingleInputDateRangePickerEvent {
  startDate: Date;
  endDate: Date;
}

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const InvisibleDatePicker = () => {
  return <DatePicker className={styles["display-none"]} />;
};

const getDisplayDateString = (startDate, endDate) => {
  const startMonth = monthNames[startDate.getMonth()];
  const startYear = startDate.getFullYear();
  const endMonth = monthNames[endDate.getMonth()];
  const endYear = endDate.getFullYear();

  if (startMonth == endMonth && startYear == endYear) {
    return `${startDate.getDate()} - ${endDate.getDate()} ${startMonth}, ${startYear}`;
  } else if (startYear == endYear) {
    return `${startDate.getDate()} ${startMonth} - ${endDate.getDate()} ${endMonth}, ${startYear}`;
  }

  return `${startDate.getDate()} ${startMonth} ${startYear} - ${endDate.getDate()} ${endMonth} ${endYear}`;
};

const SingleInputDateRangePickerCalendar = ({
  min,
  defaultValue,
  onChange,
}: MultiViewCalendarProps) => {
  const [displayedMin, setDisplayedMin] = useState<Date>(min);
  let defaultRange: SelectionRange = defaultValue as SelectionRange;

  const onSelectionChange = (event: MultiViewCalendarChangeEvent) => {
    if (defaultRange.start && !defaultRange.end) {
      onChange({
        ...event,
        value: {
          ...defaultRange,
          end: event.value as Date,
        },
      });
      setDisplayedMin(min);
    } else {
      onChange({
        ...event,
        value: {
          start: event.value as Date,
          end: null,
        },
      });
      setDisplayedMin(event.value as Date);
    }
  };

  return (
    <MultiViewCalendar
      min={displayedMin}
      value={defaultRange}
      onChange={onSelectionChange}
    />
  );
};

interface ExtendedMultiViewCalendarProps<T = any>
  extends MultiViewCalendarProps<T> {
  startDate?: Date | null;
  endDate?: Date | null;
  onClearLocalDates?: () => void;
}

const SingleInputDateRangePickerCalendarMobile = ({
  min,
  defaultValue,
  onChange,
  startDate,
  endDate,
  onClearLocalDates,
}: ExtendedMultiViewCalendarProps) => {
  const [displayedMin, setDisplayedMin] = useState<Date>(min);
  const calendarRef = useRef<HTMLDivElement>(null);
  let defaultRange: SelectionRange = defaultValue as SelectionRange;

  const onSelectionChange = (event: MultiViewCalendarChangeEvent) => {
    if (defaultRange.start && !defaultRange.end) {
      onChange({
        ...event,
        value: {
          ...defaultRange,
          end: event.value as Date,
        },
      });
      setDisplayedMin(min);
    } else {
      onChange({
        ...event,
        value: {
          start: event.value as Date,
          end: null,
        },
      });
      setDisplayedMin(event.value as Date);
    }
  };

  return (
    <div
      className={styles["mobile-multi-view-calendar-cont"]}
      ref={calendarRef}
    >
      <div className={styles["mobile-multi-view-calendar-title"]}>
        Select A Date
      </div>
      <span className={styles["mobile-multi-view-calendar-description"]}>
        Select a time and date range that you are looking to start promoting
        within.
      </span>
      <MultiViewCalendar
        min={displayedMin}
        value={defaultRange}
        onChange={onSelectionChange}
        mobileMode={true}
        views={1}
      />
      <Button
        disabled={!startDate && !endDate}
        fillMode="outline"
        onClick={(event) => {
          event.stopPropagation();
          onClearLocalDates();
        }}
      >
        Clear Dates
      </Button>
    </div>
  );
};

const DateInputBox = ({ placeholder }) => {
  const isMobile = useIsMobile();
  return (
    <div>
      {isMobile ? (
        <SvgIcon
          className={styles["mobile-calender-icon"]}
          icon={svgIcons.calendarIcon}
          size="xxlarge"
        />
      ) : (
        <DatePicker
          className={styles["date-picker-input"]}
          disabled={false}
          show={false}
          placeholder={placeholder}
          width={205}
        />
      )}
    </div>
  );
};

export interface SingleInputDateRangePickerProps {
  startDate: Date;
  endDate: Date;
  disablePastDates: boolean;
  onChange: (event: SingleInputDateRangePickerEvent) => void;
}

const SingleInputDateRangePicker = ({
  startDate,
  endDate,
  disablePastDates = false,
  onChange,
}: SingleInputDateRangePickerProps) => {
  const isMobile = useIsMobile();
  const [localDateRange, setLocalDateRange] = useState<SelectionRange>({
    start: startDate,
    end: endDate,
  });
  const [openCalendar, setOpenCalendar] = useState<boolean>(true);
  const [selectionFinalized, setSelectionFinalized] = useState<boolean>(true);
  const placeholder: string = "Select Deal Date Range";

  useEffect(() => {
    if (!isMobile) {
      if (localDateRange.end) {
        localDateRange.end.setHours(23, 59, 0, 0);
      }
      onChange({
        startDate: localDateRange.start,
        endDate: localDateRange.end,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localDateRange.end]);

  const onDateChange = (event: DateRangePickerChangeEvent) => {
    setLocalDateRange({
      start: event.value.start,
      end: event.value.end,
    });
  };

  const dateRangeString =
    !startDate || !endDate
      ? placeholder
      : getDisplayDateString(startDate, endDate);

  const kendoUIDefaultMinDate = new Date(2011, 0, 1, 8, 0, 0);
  const minDate = disablePastDates ? new Date() : kendoUIDefaultMinDate;

  const onClearLocalDates = () => {
    setLocalDateRange({
      start: null,
      end: null,
    });
  };

  return (
    <span
      className={styles["date-picker-cont"]}
      onClick={() => {
        setOpenCalendar(true);
      }}
    >
      <DateRangePicker
        onChange={onDateChange}
        className={styles["date-picker"]}
        startDateInput={DateInputBox}
        startDateInputSettings={{ placeholder: dateRangeString }}
        endDateInput={InvisibleDatePicker}
        min={minDate}
        calendarSettings={{
          // @ts-ignore
          defaultValue: localDateRange,
        }}
        defaultValue={localDateRange}
        adaptive={true}
        onCancel={() => {
          setSelectionFinalized(false);
          setLocalDateRange({
            start: startDate,
            end: endDate,
          });
        }}
        onClose={() => {
          if (isMobile) {
            if (selectionFinalized) {
              onChange({
                startDate: localDateRange.start,
                endDate: localDateRange.end,
              });
            }
            setSelectionFinalized(true);
          }
        }}
        calendar={
          isMobile
            ? (props) => (
                <div>
                  {openCalendar && (
                    <SingleInputDateRangePickerCalendarMobile
                      {...props}
                      startDate={startDate}
                      endDate={endDate}
                      onClearLocalDates={onClearLocalDates}
                    />
                  )}
                </div>
              )
            : SingleInputDateRangePickerCalendar
        }
      />
      {!isMobile && (
        <Button
          disabled={!startDate && !endDate}
          className={styles["clear-btn"]}
          fillMode="outline"
          onClick={onClearLocalDates}
        >
          Clear Dates
        </Button>
      )}
    </span>
  );
};

export default SingleInputDateRangePicker;
