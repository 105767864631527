import mixpanel from "mixpanel-browser";
import { signOut } from "next-auth/react";
import { useContext, useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useApolloClient } from "@apollo/client";
import { Button } from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import * as svgIcons from "@progress/kendo-svg-icons";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import styles from "@/components/styles/mobile-navigation-pane.module.scss";
import { MobileNavigationButton } from "@/components/mobile/MobileNavigationButton";
import {
  FilterStateContext,
  FilterStateDispatchContext,
  initialDealsFilterState,
  initialFilterState,
  initialTopProductsFilterState,
  reducerActionTypes,
} from "@/components/filters/FilterStateContext";
import { FeedbackButton } from "@/components/FeedbackButton";
import MarketSelector from "@/components/MarketSelector";
import { WavingHand } from "@/assets/WavingHand";
import { reportEventMixpanel } from "@/platform/Analytics";
import { downloadProductCatalog } from "@/components/util/gql-util";
import { PageContext } from "@/components/PageContext";

export interface MobileNavigationPaneProps {
  visible: boolean;
  onClose: () => void;
}

const navigationPages = [
  {
    title: "Top Products",
    href: "/topproducts",
    icon: <HomeOutlinedIcon className={styles["selected-icon"]} />,
    defaultFilterState: initialTopProductsFilterState,
  },
  {
    title: "Deals",
    href: "/deals",
    icon: <SavingsOutlinedIcon className={styles["selected-icon"]} />,
    defaultFilterState: initialDealsFilterState,
  },
  {
    title: "All Products",
    href: "/allproducts",
    icon: <Inventory2OutlinedIcon className={styles["selected-icon"]} />,
    defaultFilterState: initialFilterState,
  },
  {
    title: "Favorites",
    href: "/favorites",
    icon: <BookmarkBorderOutlinedIcon className={styles["selected-icon"]} />,
    defaultFilterState: initialFilterState,
  },
  {
    title: "Custom List",
    href: "/customlists",
    icon: <ReceiptLongOutlinedIcon className={styles["selected-icon"]} />,
    defaultFilterState: initialFilterState,
  },
];

export const MobileNavigationPane = ({
  visible,
  onClose,
}: MobileNavigationPaneProps) => {
  const filterState = useContext(FilterStateContext);
  const pageState = useContext(PageContext);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const maxHeight = window.innerHeight - 50;
  const maxWidth = window.innerWidth * 0.9;
  const [width, setWidth] = useState<number>(0);
  const client = useApolloClient();
  const url = new URL(document.URL);
  const pageUri = "/" + url.pathname.split("/")[1];

  const dispatch = useContext(FilterStateDispatchContext);

  useEffect(() => {
    onClose();
  }, [filterState.marketplace]);

  useEffect(() => {
    // animates the pane opening/closing
    setTimeout(() => {
      const widthFraction = maxWidth / 12;
      if (visible == true) {
        // opening the dialog
        if (width < maxWidth) setWidth(width + widthFraction);
      } else {
        // closing the dialog
        if (width > 0) {
          setWidth(width - widthFraction);
        } else {
          setShowDialog(false);
        }
      }
    }, 20);
  }, [width]);

  useEffect(() => {
    if (visible) {
      setWidth(15);
      setShowDialog(true);
    } else {
      setWidth(width - 1);
    }
  }, [visible]);

  const reportExportClick = () => {
    reportEventMixpanel({
      event: "Export Catalog",
      properties: { marketplace: filterState.marketplace, page: pageState },
    });
  };

  const downloadEntireCatalog = async () => {
    onClose();
    reportExportClick();
    downloadProductCatalog(client, filterState);
  };

  const signOutButton = async () => {
    mixpanel.reset();
    await signOut();
  };

  const onClickMobileNavigationButton = (item) => {
    dispatch({
      type: reducerActionTypes.UPDATE,
      body: {
        ...item.defaultFilterState,
      },
    });
    onClose();
  };

  return (
    <div>
      {showDialog && (
        <Dialog
          title={
            <span className={styles["dialog-title"]}>
              <span className={styles["name-hand"]}>
                <b>{"Welcome!"}</b>
                <WavingHand width={40} height={40} />
              </span>
              <FeedbackButton />
            </span>
          }
          className={styles["dialog"]}
          height={maxHeight}
          width={width}
          onClose={onClose}
        >
          <div className={styles["btn-container"]}>
            <span className={styles["section-title"]}>Areas to explore</span>
            {navigationPages.map((p, i) => {
              return (
                <MobileNavigationButton
                  key={i}
                  title={p.title}
                  selected={pageUri == p.href}
                  icon={p.icon}
                  href={p.href}
                  onClick={() => {
                    onClickMobileNavigationButton(p);
                  }}
                />
              );
            })}
          </div>
          <hr className={styles["hr-divider"]}></hr>
          <div className={styles["btn-container"]}>
            <span className={styles["section-title"]}>Marketplace</span>
            <span className={styles["market-selector"]}>
              <MarketSelector fillMode="outline" />
            </span>
          </div>
          <hr className={styles["hr-divider"]}></hr>
          <div className={styles["btn-container"]}>
            <span className={styles["section-title"]}>Account</span>
            <Button
              className={styles["action-btn"]}
              fillMode={"outline"}
              onClick={downloadEntireCatalog}
            >
              <b>Download Entire Catalog</b>
              <SvgIcon
                icon={svgIcons.downloadIcon}
                size="large"
                className={styles["download-icon"]}
              />
            </Button>
            <Button
              className={styles["action-btn"]}
              themeColor="error"
              onClick={signOutButton}
            >
              <b>Sign Out</b>
            </Button>
          </div>
        </Dialog>
      )}
    </div>
  );
};
