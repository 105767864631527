import { InMemoryCache } from "@apollo/client";

/*
  This function creates the cache that Apollo will use to retrieve and store data from the useQuery hook
  You should add to this config if you need Apollo to handle your cached results in a non default way
*/
export function buildApolloClientCache(): InMemoryCache {
  return new InMemoryCache({
    typePolicies: {},
  });
}
