import styles from "@/components/styles/label-border.module.scss";

export interface LabelBorderProps {
  label: string;
  className?: string;
}

export const LabelBorder = ({ label, className }: LabelBorderProps) => {
  return (
    <div className={className}>
      <div className={styles["label-container"]}>
        <span className={styles["label-text"]}>{label}</span>
        <hr className={styles["border"]}></hr>
      </div>
    </div>
  );
};
