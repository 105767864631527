import { createContext } from "react";
import {
  BASE_SORT_ITEMS,
  DEAL_PRICE_SORT_ITEMS,
} from "@/components/productCatalog/ProductCatalog";

export interface FilterState {
  asins: string[];
  productText: string;
  categories: [];
  priceMin: number | null;
  priceMax: number | null;
  minDealDays: number | null;
  maxDealDays: number | null;
  rating: number;
  ratingCount: number;
  discountPercent: string;
  dealType: string[];
  sorting: { field: string; order: string }[];
  pageSize: number;
  pageNumber: number;
  marketplace: string;
  requestNumber: number;
  startDate: Date;
  endDate: Date;
}

export enum reducerActionTypes {
  UPDATE,
  UPDATE_AND_REQUEST,
  UPDATE_MARKETPLACE,
}

// Partial makes all fields optional
export interface ReducerAction {
  type: reducerActionTypes;
  body: Partial<FilterState>;
}

export const initialFilterState: FilterState = {
  asins: [],
  productText: "",
  categories: [],
  priceMin: null,
  priceMax: null,
  minDealDays: null,
  maxDealDays: null,
  rating: 0,
  ratingCount: 0,
  discountPercent: "",
  dealType: [],
  sorting: [],
  pageSize: 100,
  pageNumber: 0,
  marketplace: "US",
  requestNumber: 0,
  startDate: null,
  endDate: null,
};

export const initialTopProductsFilterState: FilterState = {
  ...initialFilterState,
  rating: 4,
  ratingCount: 500,
  sorting: [BASE_SORT_ITEMS["Rating Count"]],
};

export const initialDealsFilterState: FilterState = {
  ...initialFilterState,
  sorting: [DEAL_PRICE_SORT_ITEMS["Days Until Deal Ends: Low To High"]],
};

export const filterStateReducer = (
  filterState: FilterState,
  action: ReducerAction
) => {
  switch (action.type) {
    case reducerActionTypes.UPDATE:
      return {
        ...filterState,
        ...action.body,
        requestNumber: filterState.requestNumber,
        marketplace: filterState.marketplace,
      };
    case reducerActionTypes.UPDATE_AND_REQUEST:
      return {
        ...filterState,
        ...action.body,
        requestNumber: filterState.requestNumber + 1,
        marketplace: filterState.marketplace,
      };
    case reducerActionTypes.UPDATE_MARKETPLACE:
      return {
        ...filterState,
        pageNumber: 0,
        marketplace: action.body.marketplace,
      };
  }
};

export const FilterStateContext = createContext<FilterState>(null);
export const FilterStateDispatchContext =
  createContext<React.Dispatch<ReducerAction>>(null);

export const compareFilterStates = (
  state1: FilterState,
  state2: FilterState
): boolean => {
  for (const key in state1) {
    if (state1.hasOwnProperty(key) && state2.hasOwnProperty(key)) {
      if (Array.isArray(state1[key]) && Array.isArray(state2[key])) {
        if (
          state1[key].length !== state2[key].length ||
          !state1[key].every((value, index) => value === state2[key][index])
        ) {
          return false;
        }
      } else if (key !== "requestNumber" && state1[key] !== state2[key]) {
        return false;
      }
    }
  }
  return true;
};
