import { ReactElement } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import styles from "@/components/styles/custom-window-dialog.module.scss";
import { useIsMobile } from "@/components/util/mobile-utils";

export interface CustomWindowDialogProps {
  title: string;
  description?: string;
  children?: ReactElement<any, any> | ReactElement<any, any>[];
  onClose: () => void;
  heightRatio?: number;
}

export const CustomWindowDialog = ({
  title,
  description,
  children,
  heightRatio,
  onClose,
}: CustomWindowDialogProps) => {
  const isMobile = useIsMobile();

  return (
    <Dialog
      title={
        <div className={styles["dialog-title-description"]}>
          <b className={styles["dialog-title"]}>{title}</b>
          <br />
          {description ?? <span>{description}</span>}
        </div>
      }
      className={styles["dialog"]}
      height={
        heightRatio
          ? window.innerHeight * heightRatio
          : window.innerHeight * 0.85
      }
      width={isMobile ? window.innerWidth : window.innerWidth * 0.6}
      onClose={onClose}
    >
      {children}
    </Dialog>
  );
};
