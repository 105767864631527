import { useContext, useEffect, useRef, useState } from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { useApolloClient } from "@apollo/client";
import SingleInputDateRangePicker, {
  SingleInputDateRangePickerEvent,
} from "@/components/SingleInputDateRangePicker";
import ProductCatalogPager, {
  ProductCatalogPageChangeEvent,
} from "@/components/productCatalog/ProductCatalogPager";
import styles from "@/components/styles/product-catalog.module.scss";
import ProductCatalogCard, {
  ProductCatalogCardProps,
} from "@/components/productCatalog/ProductCatalogCard";
import {
  reducerActionTypes,
  FilterStateContext,
  FilterStateDispatchContext,
} from "@/components/filters/FilterStateContext";
import { PageContext } from "@/components/PageContext";
import { AddRemoveProductDialog } from "@/components/favorites/AddRemoveProductDialog";
import { updateFavoritesListProductsRequest } from "@/components/util/favorites-list-gql-util";
import { reportFavoritedProduct } from "@/platform/Analytics";

export const BASE_SORT_ITEMS = {
  "Rating Count": {
    field: "rating_count",
    order: "desc",
  },
  Rating: {
    field: "rating",
    order: "desc",
  },
};

export const PRODUCT_PRICE_SORT_ITEMS = {
  "Price: High To Low": {
    field: "price",
    order: "desc",
  },
  "Price: Low To High": {
    field: "price",
    order: "asc",
  },
};

export const DEAL_PRICE_SORT_ITEMS = {
  "Price: High To Low": {
    field: "deal_price",
    order: "desc",
  },
  "Price: Low To High": {
    field: "deal_price",
    order: "asc",
  },
  "Days Until Deal Ends: High To Low": {
    field: "end_date",
    order: "desc",
  },
  "Days Until Deal Ends: Low To High": {
    field: "end_date",
    order: "asc",
  },
};

export const DISCOUNT_SORT_ITEMS = {
  "Discount % High To Low": {
    field: "discount",
    order: "desc",
  },
  "Discount % Low To High": {
    field: "discount",
    order: "asc",
  },
};

interface ProductCatalogProps {
  items: ProductCatalogCardProps[];
  totalItems: number;
  loading: boolean;
  sortItems: { [key: string]: { field: string; order: string } };
  includeDateFilter?: boolean;
  displayTitle?: boolean;
  refreshProducts?: () => void;
}

const ProductCatalog = ({
  items,
  totalItems,
  loading,
  sortItems,
  includeDateFilter = false,
  displayTitle = true,
  refreshProducts = () => {},
}: ProductCatalogProps) => {
  const client = useApolloClient();
  const catalogRef = useRef(null);
  const pageState = useContext(PageContext);
  const filterState = useContext(FilterStateContext);
  const [bookmarkTargetProduct, setBookmarkTargetProduct] =
    useState<ProductCatalogCardProps>();
  const [sortingItem, setSortingItem] = useState(
    filterState.sorting.length > 0 ? filterState.sorting[0] : ""
  );
  const [displayItems, setDisplayItems] = useState<ProductCatalogCardProps[]>(
    []
  );
  const [openAddProductDialog, setOpenAddProductDialog] =
    useState<boolean>(false);
  const dispatch = useContext(FilterStateDispatchContext);

  useEffect(() => {
    if (filterState.sorting.length < 1) {
      setSortingItem("");
    } else {
      for (let key in sortItems) {
        if (filterState.sorting[0] == sortItems[key]) setSortingItem(key);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.sorting]);

  useEffect(() => {
    setDisplayItems(items);
  }, [items]);

  const onPageChange = (event: ProductCatalogPageChangeEvent) => {
    dispatch({
      type: reducerActionTypes.UPDATE_AND_REQUEST,
      body: {
        ...filterState,
        pageNumber: event.pageNumber,
      },
    });
  };

  const onSortChange = (event: DropDownListChangeEvent) => {
    dispatch({
      type: reducerActionTypes.UPDATE_AND_REQUEST,
      body: {
        ...filterState,
        sorting: [sortItems[event.value]],
      },
    });
  };

  const onCalendarChange = (event: SingleInputDateRangePickerEvent) => {
    dispatch({
      type: reducerActionTypes.UPDATE_AND_REQUEST,
      body: {
        ...filterState,
        pageNumber: 0,
        startDate: event.startDate,
        endDate: event.endDate,
      },
    });
  };

  const bookmarkBtnClick = async (id: string, favorited: boolean) => {
    const targetProduct: ProductCatalogCardProps =
      items[items.findIndex((item) => item.id == id)];

    // see if the list is already in my favorites
    if (!favorited) {
      const { errors, results } = await updateFavoritesListProductsRequest(
        client,
        {
          product: id,
        }
      );
      if (errors === undefined || errors.length < 1) {
        const listName = results[0].name;
        reportFavoritedProduct(listName, targetProduct, pageState);
      }
    }

    setBookmarkTargetProduct(targetProduct);
    setOpenAddProductDialog(true);
  };

  const onBookmarkSubmit = () => {
    refreshProducts();
    setOpenAddProductDialog(false);
  };

  return (
    <div className={styles["product-catalog-container"]} ref={catalogRef}>
      <AddRemoveProductDialog
        // dynamically sets the height of the dialog to fit the window/container
        visible={openAddProductDialog}
        targetProduct={bookmarkTargetProduct}
        onSubmit={onBookmarkSubmit}
        onClose={() => {
          refreshProducts();
          setOpenAddProductDialog(false);
        }}
      />
      <div className={styles["card-cont-header"]}>
        <h1>{displayTitle ? pageState : ""}</h1>
        <div className={styles["header-action-container"]}>
          <ProductCatalogPager
            total={totalItems}
            pageSize={filterState.pageSize}
            pageNumber={filterState.pageNumber}
            onPageChange={onPageChange}
          />
          <DropDownList
            data={Object.keys(sortItems)}
            value={`Sort By: ${sortingItem}`}
            className={styles["sort-dropdown"]}
            onChange={onSortChange}
            size={"small"}
          />
        </div>
      </div>
      {includeDateFilter && (
        <div className={styles["date-picker-container"]}>
          <span className={styles["date-picker-label"]}>
            Select A Date Range:
          </span>
          <SingleInputDateRangePicker
            startDate={filterState.startDate}
            endDate={filterState.endDate}
            onChange={onCalendarChange}
            disablePastDates
          />
        </div>
      )}
      <div className={styles["product-catalog-card-container"]}>
        {loading ? (
          <Loader size="large" type="converging-spinner" />
        ) : displayItems.length > 0 ? (
          displayItems.map((card, index) => {
            return (
              <div key={index} className={styles["product-catalog-card-item"]}>
                <ProductCatalogCard
                  id={card.id}
                  asin={card.asin}
                  rating={card.rating}
                  ratingCount={card.ratingCount}
                  image={card.image}
                  productName={card.productName}
                  price={card.price}
                  discount={card.discount}
                  dealType={card.dealType}
                  promoCode={card.promoCode}
                  dealPrice={card.dealPrice}
                  marketplace={card.marketplace}
                  startDate={new Date(card.startDate)}
                  endDate={new Date(card.endDate)}
                  hasVariants={card.hasVariants}
                  favorited={card.favorited}
                  addRemoveBtnClick={bookmarkBtnClick}
                />
              </div>
            );
          })
        ) : (
          <div>No Products To Display</div>
        )}
      </div>
      <div className={styles["card-cont-footer"]}>
        <div></div>
        <ProductCatalogPager
          total={totalItems}
          pageSize={filterState.pageSize}
          pageNumber={filterState.pageNumber}
          onPageChange={onPageChange}
          centerPageNumber={true}
        />
      </div>
    </div>
  );
};

export default ProductCatalog;
